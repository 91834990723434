import { Interfaces } from '@configur-tech/discover-core-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserService from '../../services/configur/UserService';
import { AppThunk } from '../store';

const AUTHO_ID_FIELD = '66ffb342-8eb1-49b4-8dc8-fcb98ef8c31c';
const AUTH0_ID_ALIAS = 'auth0Id';

type UserData = Interfaces.User;

export interface UserState {
  data: UserData | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  data: null,
  loading: false,
  error: null,
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Fetch user
    fetchUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserSuccess(state, action: PayloadAction<Interfaces.User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    // Update user
    updateUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    updateUserSuccess(state, action: PayloadAction<Interfaces.User>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    updateUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
} = UserSlice.actions;

export default UserSlice.reducer;

export const fetchUser =
  (token: string, auth0Id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchUserStart());
      const fetched = await UserService.getUser(
        token,
        JSON.stringify([
          {
            operator: '=',
            field: AUTHO_ID_FIELD,
            alias: AUTH0_ID_ALIAS,
            datasetMetaId: process.env.REACT_APP_USER_DATASET_META,
            value: { type: 'CONSTANT', value: auth0Id },
          },
        ]),
      );

      const processed = {
        ...fetched.data.entries[0],
      };

      dispatch(fetchUserSuccess(processed as Interfaces.User));
    } catch (error) {
      const err = error as string;
      dispatch(fetchUserFailure(err.toString()));
    }
  };

export const updateUser =
  (token: string, userData: Interfaces.User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(updateUserStart());

      const preprocessed = {
        ...userData,
        savedCompanies:
          userData.savedCompanies[0] === ''
            ? userData.savedCompanies[1]
            : userData.savedCompanies.join(', '),
      };

      const updated = await UserService.putUser(token, preprocessed);

      const processed = {
        ...updated.data.entry,
        savedCompanies: (
          updated.data.entry.savedCompanies as unknown as string
        ).split(', '),
      };

      dispatch(updateUserSuccess(processed));
    } catch (error) {
      const err = error as string;
      dispatch(updateUserFailure(err.toString()));
    }
  };
